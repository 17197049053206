<template>
  <div class="container">
    <NavBar />
    <div class="page_title">Log</div>

    <v-select
      class="octo-select"
      v-model="selectedID"
      label="Choose a device"
      :items="deviceDetailsForSelect"
      item-title="text"
      item-value="value"
      variant="outlined"
      @update:modelValue="viewLog()"
    ></v-select>

    <div v-show="selectedID != ''">
      <div class="date_nav">
        <v-btn class="tuya_btn" variant="text" @click="prevDate()">
          Prev
        </v-btn>
        {{ currentDate }}
        <v-btn class="tuya_btn" variant="text" @click="nextDate()">
          Next
        </v-btn>
      </div>
      <div class="chartLog">
        <TemperatureChart :data="logDetails" />
      </div>
      <div class="time_table">
        <v-data-table
          height="60vh"
          fixed-header
          :headers="fields"
          :items="logTableDetails"
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";
import TemperatureChart from "./TemperatureChart.vue";

export default {
  name: "Log",
  components: { NavBar, VDataTable, TemperatureChart },
  data() {
    return {
      offset: 0,
      currentDate: null,
      logDetails: [],
      logTableDetails: [],
      selectedID: "",
      deviceDetails: [],
      deviceDetailsForSelect: [],
      fields: [
        // { key: "deviceName", title: "Device", sortable: false },
        { key: "logTime", title: "Time", sortable: true },
        { key: "state", title: "Action", sortable: true },
        { key: "tempSet", title: "Set Temp", sortable: true },
        { key: "tariff", title: "Tariff", sortable: true },
      ],
      backendurl: process.env.VUE_APP_API,
      headers: [],
    };
  },
  created() {
    this.getDevices();
  },
  methods: {
    getDevices() {
      this.message = null;
      var postdata = {
        module: "settings",
        action: "getDevices",
        key: this.$store.state.authKey,
        detail: {},
      };

      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          this.deviceDetailsForSelect = res.data;
        }
      });
    },

    viewLog() {
      this.logDetails = [];
      var postdata = {
        module: "settings",
        action: "allLogs",
        key: this.$store.state.authKey,
        detail: { deviceId: this.selectedID, offset: this.offset },
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          this.logDetails = res.data;
          this.currentDate = res.data.date;
          this.logTableDetails = [...res.data.actionData];
        }
      });
    },
    prevDate() {
      this.offset++;
      this.viewLog();
    },
    nextDate() {
      if (this.offset > 0) {
        this.offset--;
        this.viewLog();
      }
    },
  },
};
</script>

<style scoped>
.tuya_btn {
  background-color: lightgray;
}
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
.time_table {
  padding-top: 50px;
  margin: auto;
  width: 75%;
}
.chartLog {
  margin: auto;
  height: 50vh;
}
.date_nav {
  margin-bottom: 20px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .time_table {
    padding-top: 10px;
    width: 100%;
  }
  .chartLog {
    height: 50vh;
  }
}
</style>
