<template>
  <v-dialog v-model="addDeviceDialog">
    <v-card>
      <v-card-text>
        <div>Add device</div>
        Device ID:
        <v-text-field
          label="Device ID"
          type="text"
          v-model="newDeviceID"
        ></v-text-field>
        Device Name:
        <v-text-field
          label="Device Name"
          type="text"
          v-model="newDeviceName"
        ></v-text-field>
        Type
        <v-text-field
          label="Type"
          type="text"
          v-model="newDeviceType"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="text-none vuwbo_button"
          variant="text"
          @click="addDeviceDialog = false"
          >Cancel</v-btn
        >
        <v-btn
          id="recoverPasswordBtn"
          class="text-none vuwbo_button"
          variant="text"
          @click="confirmAddDevice()"
          >Save</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="deleteDeviceDialog">
    <v-card>
      <v-card-text>
        <div>Confirm delete device</div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="text-none vuwbo_button"
          variant="text"
          @click="deleteDeviceDialog = false"
          >Cancel</v-btn
        >
        <v-btn
          id="recoverPasswordBtn"
          class="text-none vuwbo_button"
          variant="text"
          @click="confirmDeleteDevice()"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div class="container">
    <NavBar />
    <div class="page_title">Tuya Api</div>

    <div class="flex_button">
      <div class="device_buttons">
        <v-select
          class="octo-select"
          v-model="selectedID"
          label="Choose a device"
          :items="deviceDetailsForSelect"
          item-title="text"
          item-value="value"
          variant="outlined"
          @update:modelValue="viewTimes()"
        ></v-select>
      </div>
      <div class="device_buttons">
        <v-btn
          v-show="selectedID != '' && edited"
          density="compact"
          class="tuya_btn room_button"
          variant="outlined"
          @click="updateTimes()"
          >Update Times</v-btn
        >

        <v-btn
          v-show="selectedID != ''"
          density="compact"
          class="tuya_btn room_button"
          variant="outlined"
          @click="defaultTimes()"
          >Set default</v-btn
        >
        <v-btn
          v-show="selectedID != ''"
          density="compact"
          class="tuya_btn room_button"
          variant="outlined"
          @click="deleteDevice()"
          >Delete device</v-btn
        >
        <v-btn
          density="compact"
          class="tuya_btn room_button"
          variant="outlined"
          @click="addDevice()"
          >Add device</v-btn
        >
      </div>
    </div>
    <div v-show="selectedID != '' && state != 'updating'" class="time_table">
      <div class="device_buttons_timetable flex_button">
        <div>
          <v-checkbox
            v-model="copyDown"
            label="Copy down"
            hide-details="true"
            density="compact"
          ></v-checkbox>
        </div>
        <!-- <v-select
          class="octo-select"
          v-model="selectedCopyID"
          label="Copy from"
          :items="deviceDetailsForSelect"
          item-title="text"
          item-value="value"
          variant="outlined"
          @update:modelValue="copyTimes()"
        ></v-select> -->
        <div>
          <v-btn class="tuya_btn room_button" variant="text" @click="copyWdWe()"
            >Copy Weekday to weekend</v-btn
          >
        </div>
      </div>
      <v-data-table
        height="60vh"
        fixed-header
        :headers="fields"
        :items="deviceDetailFiltered"
      >
        <template v-slot:item.weekdayTimes="{ item }">
          <div v-for="time in item.selectable.weekdayTimes" :key="time">
            {{ time.startTime }} to {{ time.endTime }}
            <v-btn
              class="tuya_btn"
              variant="text"
              @click="
                changeTemp(
                  'WD',
                  item.columns.deviceID,
                  time.startTime,
                  time.tempSet,
                  1
                )
              "
              >↑</v-btn
            >
            <v-btn
              class="tuya_btn"
              variant="text"
              @click="
                changeTemp(
                  'WD',
                  item.columns.deviceID,
                  time.startTime,
                  time.tempSet,
                  -1
                )
              "
              >↓</v-btn
            >{{ showTemp(item, time.tempSet) }}
          </div>
        </template>
        <template v-slot:item.weekendTimes="{ item }">
          <div v-for="time in item.selectable.weekendTimes" :key="time">
            {{ time.startTime }} to {{ time.endTime }}
            <v-btn
              class="tuya_btn"
              variant="text"
              @click="
                changeTemp(
                  'WE',
                  item.columns.deviceID,
                  time.startTime,
                  time.tempSet,
                  1
                )
              "
              >↑</v-btn
            >
            <v-btn
              class="tuya_btn"
              variant="text"
              @click="
                changeTemp(
                  'WE',
                  item.columns.deviceID,
                  time.startTime,
                  time.tempSet,
                  -1
                )
              "
              >↓</v-btn
            >{{ showTemp(item, time.tempSet) }}
          </div>
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>
    <div v-show="state == 'updating'">Updating</div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "Settings",
  components: { NavBar, VDataTable },
  data() {
    return {
      edited: false,
      copyDown: false,
      selectedID: "",
      selectedCopyID: "",
      addDeviceDialog: false,
      deleteDeviceDialog: false,
      newDeviceID: "",
      newDeviceName: "",
      newDeviceType: "",
      currentDevice: "",
      currentDeviceID: null,
      currentDeviceStatus: "",
      state: "select",
      deviceDetailsForSelect: [],
      deviceDetails: [],
      deviceDetailFiltered: [],
      fields: [
        { key: "deviceID", title: "ID", sortable: false, align: " d-none" },
        { key: "weekdayTimes", title: "Weekday Times", sortable: true },
        { key: "weekendTimes", title: "Weekend Times", sortable: true },
      ],
      backendurl: process.env.VUE_APP_API,
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.deviceDetails = [];
      var postdata = {
        module: "settings",
        action: "allSettings",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          this.deviceDetails = res.data;
          this.deviceDetailsForSelect = [];
          for (let i = 0; i < this.deviceDetails.length; i++) {
            const device = {
              value: this.deviceDetails[i].deviceID,
              text: this.deviceDetails[i].deviceName,
            };
            this.deviceDetailsForSelect.push(device);
          }
          this.viewTimes();
          this.edited = false;
        }
      });
    },

    updateTimes() {
      this.state = "updating";
      var postdata = {
        module: "settings",
        action: "timeSettings",
        key: this.$store.state.authKey,
        detail: { deviceDetails: this.deviceDetails },
      };
      Axios.post(this.backendurl, postdata, this.headers).then(() => {
        this.getSettings();
      });
    },
    viewTimes() {
      const index = this.deviceDetails.findIndex(
        (e) => e.deviceID == this.selectedID
      );
      if (index != -1) {
        this.currentDeviceID = this.deviceDetails[index].deviceID;
        this.currentDevice = this.deviceDetails[index].deviceName;
        this.currentDeviceStatus =
          this.deviceDetails[index].deviceStatus == "y"
            ? "Active"
            : "Holiday mode";
        this.deviceDetailFiltered = this.deviceDetails.filter(
          (device) => device.deviceID === this.selectedID
        );
        this.state = this.selectedID;
      }
    },
    defaultTimes() {
      var postdata = {
        module: "settings",
        action: "defaultSettings",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then(() => {
        this.getSettings();
        this.currentDeviceStatus = null;
      });
    },
    holidayMode() {
      var postdata = {
        module: "settings",
        action: "holidaySettings",
        key: this.$store.state.authKey,
        detail: {
          deviceID: this.currentDeviceID,
          deviceStatus: this.currentDeviceStatus,
        },
      };
      Axios.post(this.backendurl, postdata, this.headers).then((response) => {
        this.getSettings();
        this.currentDeviceStatus = response.data.currentStatus;
      });
    },
    showTemp(item, tempSet) {
      if (item.raw.deviceType == "plug") {
        tempSet = tempSet == -1 ? "Off" : "On";
      }
      return tempSet;
    },
    addDevice() {
      this.addDeviceDialog = true;
    },
    confirmAddDevice() {
      var postdata = {
        module: "settings",
        action: "addDevice",
        key: this.$store.state.authKey,
        detail: {
          deviceID: this.newDeviceID,
          deviceName: this.newDeviceName,
          deviceType: this.newDeviceType,
        },
      };
      Axios.post(this.backendurl, postdata, this.headers).then((response) => {
        this.getSettings();
        this.currentDeviceStatus = response.data.currentStatus;
        this.addDeviceDialog = false;
      });
    },
    deleteDevice() {
      this.deleteDeviceDialog = true;
    },
    confirmDeleteDevice() {
      var postdata = {
        module: "settings",
        action: "deleteDevice",
        key: this.$store.state.authKey,
        detail: {
          deviceID: this.selectedID,
        },
      };
      Axios.post(this.backendurl, postdata, this.headers).then((response) => {
        this.getSettings();
        console.log(response.data);
        this.deleteDeviceDialog = false;
        this.state = "select";
        this.selectedID = "";
      });
    },
    copyTimes() {
      console.log("ID to copy " + this.selectedCopyID);
    },
    copyWdWe() {
      const deviceIndex = this.deviceDetails.findIndex(
        (e) => e.deviceID == this.currentDeviceID
      );
      if (deviceIndex != -1) {
        this.edited = true;
        let deviceTimesWD = this.deviceDetails[deviceIndex].weekdayTimes;
        let deviceTimesWE = this.deviceDetails[deviceIndex].weekendTimes;
        for (let i = 0; i < deviceTimesWD.length; i++) {
          deviceTimesWE[i]["tempSet"] = deviceTimesWD[i]["tempSet"];
        }
      }
    },
    changeTemp(type, deviceID, startTime, temp, change) {
      const deviceIndex = this.deviceDetails.findIndex(
        (e) => e.deviceID == deviceID
      );
      if (deviceIndex != -1) {
        this.edited = true;
        if (type == "WD") {
          let deviceTimes = this.deviceDetails[deviceIndex].weekdayTimes;
          const timeIndex = deviceTimes.findIndex(
            (e) => e["startTime"] == startTime
          );
          if (timeIndex != -1) {
            if (this.deviceDetails[deviceIndex].deviceType == "plug") {
              if (temp == -1 && change == -1) {
                change = 0;
              }
              if (temp == 0 && change == 1) {
                change = 0;
              }
            }
            if (this.copyDown) {
              for (let i = timeIndex; i < deviceTimes.length; i++) {
                deviceTimes[i]["tempSet"] = temp + change;
              }
            } else {
              deviceTimes[timeIndex]["tempSet"] = temp + change;
            }
          }
        } else {
          let deviceTimes = this.deviceDetails[deviceIndex].weekendTimes;
          const timeIndex = deviceTimes.findIndex(
            (e) => e["startTime"] == startTime
          );
          if (timeIndex != -1) {
            if (this.deviceDetails[deviceIndex].deviceType == "plug") {
              if (temp == -1 && change == -1) {
                change = 0;
              }
              if (temp == 0 && change == 1) {
                change = 0;
              }
            }
            if (this.copyDown) {
              for (let i = timeIndex; i < deviceTimes.length; i++) {
                deviceTimes[i]["tempSet"] = temp + change;
              }
            } else {
              deviceTimes[timeIndex]["tempSet"] = temp + change;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.device_buttons_timetable {
  margin: 10px;
  display: flex;
  gap: 20px;
}
.octo-select {
  min-width: 200px;
}
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
.tuya_btn {
  background-color: lightgray;
  margin: 3px;
}
.page_item {
  margin-top: 20px;
}
.page_item_buttons {
  display: flex;
  gap: 20px;
}
.device_table {
  border: 1px grey solid;
}
.info_section {
  margin-top: 10px;
}
.info_section_flex {
  display: flex;
}
.top_align {
  vertical-align: top;
  height: 100%;
}
.flex_button {
  display: flex;
  justify-content: space-between;
}
.device_buttons {
  display: flex;
}
.time_table {
  border: 1px solid grey;
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .room_button {
    font-size: 10px;
  }
  .flex_button {
    flex-direction: column;
  }
  .device_buttons_timetable {
    gap: 0;
  }
}
</style>
